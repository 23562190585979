export default `<div class="bannerTop">
<p>¿ERES NUEV@? SUSCRÍBETE YA Y DISFRUTA DE UN <strong>20% DE DESCUENTO</strong> EN EL PLAN ANUAL. USA CÓDIGO <strong>1FX3P</strong></p>
</div>

<!--BARRA DE NAVEGACION-->

<header class="cabecera">
<div class="barranav">

   <div class="logoa3p"><img class="logo-img" src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/A3P-generic-landing.png"></a>
   </div>

<div class="m_sesion">
  <a class="sesion" href="https://www.atresplayer.com/login?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2luZGV4Lmh0bWwiLCJ0eXBlT2ZFbnRyeVBvaW50IjoibGFuZGluZyIsInBhY2thZ2VJZCI6ODc1NTYwfQ==" title="Ir al login de Atresplayer">Iniciar sesión I</a>
  <a class="sesion2 m_sucribete-link button" title="Ir al login de Atresplayer">SUSCRÍBETE</a>
</div>

</div>
</header>

<!--MÓDULO 1-->

<main>
  <div class="modulo1">

    <div class="TextoModulo1">
      <div class="claim1">CANALES DE TV, SERIES Y PROGRAMAS.</div>
      <div class="claim1">Estrenos todos los días</div>
    </div>
    

  <div class="planes">
    <div class="claim2">Elige tu plan internacional</div>

    <div class="opciones">
            <div class="tipo1">
        <p>Mensual</p>
        <div class="precios"> $4.99</div>
        <div class="boton"><a class="m_sucribete-link button">Pruébanos</a></div>
      </div>

      <div class="tipo2">
        <p>Anual</p>
        <div class="precios">$49.99</div>
        <div class="info">Ahorra 2 meses</div>
        <div class="boton"><a class="m_sucribete-link button">Suscríbete</a></div>
      </div>
    </div>

  </div>
</div>

<!--MÓDULO 2-->

<div class="modulo2">

 <div class="TextoModulo2">
  <div class="textoDispositivos">DESDE CUALQUIER PAÍS Y EN TODOS TUS DISPOSITIVOS</div>
</div>

<div class="dispoytexto">

<div class="imgDispo"><img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/BODEGON.png">
</div>

<div class="textoDispo">Consulta los dispositivos compatibles <a href="https://premium.atresplayer.com/dispositivos/" target="_blank"><u>AQUÍ</u></a>
</div>

</div>

<div class="ListaVentajas">
  <ul class="listado">
   <li>
     <p>Sin permanencia</p>
   </li>
   <li>
     <p>Contenido exclusivo</p>
   </li>
   <li>
     <p>Sin publicidad</p>
   </li>
   <li>
     <p>Canales de TV en directo</p>
   </li>
   </ul>    	
</div>

<div class="canales">
  <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/A3I-generic-landing.png" title="Antena 3">
  <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/SEXTA-generic-landing.png" title="La Sexta">
  <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/A3S-generic-landing.png" title="Atreseries">
  <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/A3C-generic-landing.png" title="Atrescine">
  <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/HOLATV-generic-landing.png" title="Hola TV">
</div>

</div>

<!--MÓDULO 3-->

<div class="modulo3">

<div class="TextoModulo3">
  <div class="textoStreaming">LA PLATAFORMA STREAMING DE ESPAÑA MÁS <b>COMPLETA</b></div>

  <section class="awSlider">
		  <div  class="carousel slide" data-ride="carousel">
			<div class="carousel-inner" role="listbox">

			  <div class="item active">
				<figure>
					<picture>
					
						<source type="image/jpeg" media="(max-width: 599px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/CARDO2-2.jpg">
						<source type="image/jpeg" media="(min-width: 600px) and (max-width: 1150px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/CARDO2.jpg">
						<img alt="Cardo 2" src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/CARDO2.jpg">
					</picture>
				</figure>
			  </div>

			  <div class="item">
				<figure>
					<picture>
						<source type="image/jpeg" media="(max-width: 599px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/TIEMPO-2.jpg">
						<source type="image/jpeg" media="(min-width: 600px) and (max-width: 1150px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/TIEMPO.jpg">
						<img alt="El tiempo no para" src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/TIEMPO.jpg">
					</picture>
				</figure>
			  </div>

			  <div class="item">
				<figure>
					<picture>
						<source type="image/jpeg" media="(max-width: 599px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/PENULTIMA-2.jpg">
						<source type="image/jpeg" media="(min-width: 600px) and (max-width: 1150px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/PENULTIMA.jpg">
						<img alt="La penúltima y me voy" src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/PENULTIMA.jpg">
					</picture>
				</figure>
			  </div>

			  <div class="item">
				<figure>
					<picture>
						<source type="image/jpeg" media="(max-width: 599px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/LA_RUTA_2.jpg">
						<source type="image/jpeg" media="(min-width: 600px) and (max-width: 1150px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/LA_RUTA.jpg">
						<img alt="La Ruta" src="https://suscripcion.atresplayer.com/hazte-premium/internacional/img/LA_RUTA.jpg">
					</picture>
				</figure>
			  </div>

			  <div class="item">
				<figure>
					<picture>
						<source type="image/jpeg" media="(max-width: 599px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/ANGLES-2.jpg">
						<source type="image/jpeg" media="(min-width: 600px) and (max-width: 1150px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/ANGLES.jpg">
						<img alt="Anglés" src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/ANGLES.jpg">
					</picture>
				</figure>
			  </div>

			  <div class="item">
				<figure>
					<picture>
						<source type="image/jpeg" media="(max-width: 599px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/ELDESAFIO-2.jpg">
						<source type="image/jpeg" media="(min-width: 600px) and (max-width: 1150px)" srcset="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/ELDESAFIO.jpg">
						<img alt="El Desafío" src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/ELDESAFIO.jpg">
					</picture>
				</figure>
			  </div>

			</div>

			<!-- Controls -->
			<a class="left carousel-control" href=".carousel" role="button" data-slide="prev">
			</a>
			<a class="right carousel-control" href=".carousel" role="button" data-slide="next">
			</a>
		  </div>
		</section>

<div class="tipos">
  <div class="boton"><a class="m_sucribete-link button" href="https://www.atresplayer.com/usuario/cuenta/suscripcion-y-paquetes">SUSCRÍBETE YA</a></div>
</div>

</div>



</div>

</div>

<!--FOOTER-->

<footer class="footer"> 
  <div class="pie">
    <div class="listaCanales">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/img/canales-footer-generic-landing.png" />
    </div>

    <div class="textos-pie">
      <div class="textos-pie-listado">

        <div><a href="https://statics.atresmedia.com/atresplayer/assets/legal/condiciones-de-participacion.html">Condiciones de uso</a></div>

        <div><a href="https://statics.atresmedia.com/atresplayer/assets/legal/proteccion.html">Política de privacidad</a></div>

        <div><a href="https://statics.atresmedia.com/atresplayer/assets/legal/politica-de-cookies.html">Política de cookies</a></div>

                </div>

      <div class="textos-pie-listado2">	
        <div><a href="https://statics.atresmedia.com/atresplayer/assets/faqs/preguntas-generales.html">Preguntas frecuentes</a></div>
        <div><a href="https://statics.atresmedia.com/atresplayer/assets/legal/legal.html">Aviso legal</a></div>
        <div>Contacto </div>
      </div>
    </div>
  </div>
  <div class="nota_legal">© Atresmedia Corporación de Medios de Comunicación, S.A - A. Isla Graciosa 13, 28703, S.S. de los Reyes, Madrid. Reservados todos los derechos. <a href="https://www.atresplayer.com/mapa-del-sitio/"> Mapa Web</a> </div>

</footer>

</main>`
