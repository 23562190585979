export default `<header class="hero flex bg-cover">
<div class="wrapper cf">
  <hgroup class="hero__headings flex">
    <div class="hero__headings-box flex">
      <span class="hero__atp-logo bg-cover">
        <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/atresplayer-nova-logo.svg" />
      </span>
      <p class="title">¿Te imaginas vivir una historia de <span>amor</span> continua?</p>
      <h1 class="explanation">Las mejores telenovelas y series en exclusiva y sin anuncios por solo <span>1,99€</span></h1>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">Lo quiero</a>
    </div>
  </hgroup>
<span class="hero__scroll bg-cover"></span>
</div>
</header>
<section class="content">
<div class="wrapper cf">
  <!-- item -->
  <article class="content__item flex">
    <figure class="content__item-image">
      <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/seleccion.png" alt="La mejor selección">
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <p class="title primary-color">La mejor selección de telenovelas</p>
      <h3 class="text">Un catálogo inédito en España y en constante crecimiento: más de 10.000 horas de los mejores títulos con todo el amor y la pasión que estabas esperando.</h3>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">Una experiencia única</a>
    </hgroup>
    <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/section-bg-1.jpg" class="content-bg">
  </article>
  <!-- item -->
  <article class="content__item flex">
    <figure class="content__item-image light-gradient">
      <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/exclusiva.png" alt="Exclusiva en España">
      <span class="luz"></span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <p class="title primary-color">Exclusiva en España</p>
      <h3 class="text">Te ofrecemos apasionantes telenovelas nunca vistas en España y que solo tú podrás disfrutar al contratar Novelas Nova.</h3>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">Solo para ti</a>
    </hgroup>
    <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/section-bg-2.jpg" class="content-bg">
  </article>
  <!-- item -->
  <article class="content__item flex">
    <figure class="content__item-image light-gradient">
      <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/multipantalla.png" alt="Multipantalla">
      <span class="luz"></span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <p class="title primary-color">Multipantalla</p>
      <h3 class="text">Disfruta del amplio contenido de Novelas Nova desde cualquier dispositivo. </h3>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">Como quieras</a>
    </hgroup>
    <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/section-bg-3.jpg" class="content-bg">
  </article>
  <!-- item -->
  <article class="content__item flex">
    <figure class="content__item-image light-gradient">
      <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/sinpublicidad.png" alt="Sin interrupciones">
      <span class="luz alt"></span>
    </figure>
    <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
      <p class="title primary-color">Sin interrupciones y con la máxima calidad</p>
      <h3 class="text">Puedes ver todos los capítulos donde y cuando quieras, sin cortes y con la mejor calidad de imagen HD-4K.</h3>
      <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">Sin compromiso</a>
    </hgroup>
    <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/section-bg-4.jpg" class="content-bg">
  </article>
</div>
</section>
<section class="bottom__call">
<div class="wrapper">
  <article class="box flex">
    <span class="hero__atp-logo bg-cover">
      <img src="https://novelas-nova.atresplayer.com/hazte-premium/primer/img/atresplayer-nova-logo.svg" />
    </span>
    <!--<span class="hero__scroll bg-cover"></span>-->
    <h2 class="title">Disfruta de tus telenovelas y series <span>favoritas</span></h2>
    <a class="button" href="https://api.atresplayer.com/login?redirect=https%3A%2F%2Fwww.atresplayer.com%2Fusuario%2Fsuscripcion%2Fpaquetes%2Fpaquete%2F1706986%3FrollbackFunnelsParams%3DeyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwb3N0cGF5bWVudCI6Imh0dHBzOi8vd3d3LmF0cmVzcGxheWVyLmNvbS9ub3ZlbGFzLW5vdmEvIn0%3D&additionalData=eyJmdW5uZWxzIjp7ImNvbW1vbnNWYXJzIjp7InR5cGVQcm9jZXNzIjoic3Vic2NyaXBjaW9uIiwiZnVubmVsIjp7InN0ZXAiOjAsImRlc2NyaXB0aW9uIjoic3Vic2NyaXBjaW9uIn0sInN0YXR1cyI6ImluaWNpbyIsImVudHJ5UG9pbnQiOiJib3RvbmxhbmRpbmcifSwidHJhY2tMb2dpbiI6dHJ1ZSwidHJhY2tTdWJzY3JpcHRpb24iOnRydWV9LCJwcm9tb1NpZ251cCI6InRlbGVub3ZlbGFzIiwic2tpcEFjdGl2YXRpb24iOnRydWUsInBvc3RwYXltZW50IjoiaHR0cHM6Ly93d3cuYXRyZXNwbGF5ZXIuY29tLyJ9==">CONTRATA AHORA</a>
  </article>
</div>
</section>
<footer class="foo flex">
<div class="wrapper cf">
  <p class="creds">© Atresmedia Corporación de Medios de Comunicación, S.A - A. Isla Graciosa 13, 28703, S.S. de los Reyes, Madrid. Reservados todos los derechos</p>
</div>
</footer>`;
