export default `<header class="hero flex bg-cover">
<div class="wrapper cf">
  <hgroup class="hero__headings flex">
    <span class="hero__atp-logo bg-cover">
      <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/promocion2/img/atresplayer-logo.svg" />
    </span>
    <p class="title">¡Oferta exclusiva!<span> 30% de descuento</span> en el Plan Anual</p>
    <div class="hero__planes">
      <p class="explanation">Contrátalo ya desde cualquier país</p>
      <div class="row">
        <!-- plan -->
        <div class="hero__plan familiar">
          <div class="hero__plan-list">
            <p class="hero__plan-logo">PLAN<span>internacional</span></p>
            <ul class="hero__list">
              <li><h2>Originales y exclusivos</h2></li>
              <li><h2>Preestrenos</h2></li>
              <li><h2>Subtítulos en español</h2></li>
              <li><h2>3 perfiles</h2></li>
              <li><h2>Sin publicidad</h2></li>
              <li><h2>Descarga offline</h2></li>
              <li><h2>Calidad 4K</h2></li>
            </ul>
          </div>

          <div class="planAnual"><center>PLAN ANUAL</center>
          </div>

          <div class="hero__plan-precios">

            <span class="precio">
              <span class="pre">ANTES</span>
              <span><del>US $49.99</del></span>
            </span>

            <span class="precio">
              <span class="pre">AHORA</span>
              <span>US $34.99</span>
              <span class="add"><br>30% de descuento</br></span>
            </span>

          </div>

        </div>
      </div>
      <a class="button_oferta" title="Adquirir oferta 20% de descuento del plan anual" href="https://www.atresplayer.com/usuario/cuenta/suscripcion-y-paquetes/carrito/LQXBLAN">30% de descuento</a>
    </div>
    
  </hgroup>

<span class="hero__scroll bg-cover"></span>
</div>
</header>
<section class="content">
<div class="wrapper cf">
  <ul class="content__features">
    *Oferta válida para usuarios que contraten el plan anual internacional con tarjeta o paypal. Renovación a precio tarifa vigente.
  </ul>
</div> 


<footer class="foo flex">
<div class="wrapper cf">
  <p class="creds">© Atresmedia Corporación de Medios de Comunicación, S.A - A. Isla Graciosa 13, 28703, S.S. de los Reyes, Madrid. Reservados todos los derechos</p>
</div>
</footer>
`;
