export default `<div class="barraNavegacion">

<div class="logoBarraTareas">
<img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/operadores/img/A3PBLANCO2.png">
</div>
 
  <div class="m_sesion">

<div class="logins">
<a class="m_sesion-link2" href="https://www.atresplayer.com/registro?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2V4cGF0LyIsInR5cGVPZkVudHJ5UG9pbnQiOiJsYW5kaW5nIiwicGFja2FnZUlkIjo4NzU1NjB9">¿Tienes cuenta? I</a>
<a class="m_sesion-link" href="https://www.atresplayer.com/login?additionalData=eyJvcmlnaW5MYW5kaW5nIjoiaHR0cHM6Ly9zdXNjcmlwY2lvbi5hdHJlc3BsYXllci5jb20vaGF6dGUtcHJlbWl1bS9pbnRlcm5hY2lvbmFsL2luZGV4Lmh0bWwiLCJ0eXBlT2ZFbnRyeVBvaW50IjoibGFuZGluZyIsInBhY2thZ2VJZCI6ODc1NTYwfQ==" title="Ir al login de Atresplayer">INICIA SESIÓN</a>
</div>

</div>

</div>

<header class="cabecera">

<div class="topContent">
<h1 class="logo">
  <a href="https://www.atresplayer.com/"><img class="logo-img" src="https://suscripcion.atresplayer.com/hazte-premium/internacional/operadores/img/A3PBLANCO.png" alt="Atresplayer" /></a>
</h1>			

<div class="claim">
  Las mejores series, programas y películas de España
</div>
<div class="claim2">
  Vincula tu operador a ATRESplayer y disfruta de contenidos originales y canales en vivo sin restricciones
</div>

<div class="f_suscribete">

  <div class="m_suscribete">
    <a class="m_sucribete-link button">ACCEDE CON TU OPERADOR</a>
  </div>
</div>
</div>

</header>

<main>
  <div>

    <div class="textoPrevio">Vincular tu operador es muy sencillo
       <div class="pasos">

      <div class="steps">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/operadores/img/1.png">
      </div>

      <div class="steps">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/operadores/img/2.png">
      </div>

      <div class="steps">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/operadores/img/3.png">
      </div>

      <div class="steps">
        <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/operadores/img/4.png">
      </div>
    </div>

      </div>

<div class="list_ventajas">
   <div class="canales">
    <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/img/A3I.png" title="Antena 3">
    <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/img/SEXTA.png" title="La Sexta">
    <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/img/A3S.png" title="Atreseries">
    <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/img/A3C.png" title="Atrescine">
    <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/img/holatv.png" title="¡Hola!TV">
    </div>  

  <div class="PadreListado">
     <ul class="listado">
     <li>
       <p>Contenido original y exclusivo</p>
     </li>
     <li>
       <p>Sin permanencia</p>
     </li>
     <li>
       <p>Descarga offline</p>
     </li>
     <li>
       <p>Subtítulos en español</p>
     </li>
     </ul>

    <div class="txtAbajoBanderas">
      <p class="txtAbajoBanderasNormal">Vincula tu operador y</p>
      <p class="txtAbajoBanderasNegrita">disfruta de ATRESplayer</p>
    </div>

  </div>
</div>
 
<div class="bodegon">
  
  <div class="m_suscribete2">
    <a class="m_sucribete-link2 button">ACCEDE CON TU OPERADOR</a>
  </div>
    
</div>
</div>

<footer class="footer"> 
<div class="pie">
  <div>
    <img src="https://suscripcion.atresplayer.com/hazte-premium/internacional/operadores/img/canales-footer.png" />
  </div>

  <div class="textos-pie">
    <div class="textos-pie-listado">

      <div><a href="https://statics.atresmedia.com/atresplayer/assets/legal/condiciones-de-participacion.html">Condiciones de uso</a></div>

      <div><a href="https://statics.atresmedia.com/atresplayer/assets/legal/proteccion.html">Política de privacidad</a></div>

      <div><a href="https://statics.atresmedia.com/atresplayer/assets/legal/politica-de-cookies.html">Política de cookies</a></div>

              </div>

    <div class="textos-pie-listado2">	
      <div><a href="https://statics.atresmedia.com/atresplayer/assets/faqs/preguntas-generales.html">Preguntas frecuentes</a></div>
      <div><a href="https://statics.atresmedia.com/atresplayer/assets/legal/legal.html">Aviso legal</a></div>
      <div>Contacto </div>
    </div>
  </div>
</div>
<div class="nota_legal">© Atresmedia Corporación de Medios de Comunicación, S.A - A. Isla Graciosa 13, 28703, S.S. de los Reyes, Madrid. Reservados todos los derechos. <a href="https://www.atresplayer.com/mapa-del-sitio/"> Mapa Web</a> </div>

</footer>

</main>
`;
