import nationalTemplate from './templates/national';
import nationalPromoTemplate from './templates/national/promo';

import novelasTemplate from './templates/novelas';
import internationalTemplate from './templates/international';
import expatTemplate from './templates/international/expat';
import mexicoTemplate from './templates/international/mexico';
import latamTemplate from './templates/international/latam';
import latamUSATemplate from './templates/international/latamUSA';
import operadoresTemplate from './templates/international/operadores';
/* import developTemplate from './templates/international/develop'; */
import usaTemplate from './templates/international/usa';

import expatPromoTemplate from './templates/international/expatPromo';
import latamPromoTemplate from './templates/international/latamPromo';
import mexicoPromoTemplate from './templates/international/mexicoPromo';
import usaPromoTemplate from './templates/international/usaPromo';

import expatPromo2Template from './templates/international/expatPromo2';
import latamPromo2Template from './templates/international/latamPromo2';
import mexicoPromo2Template from './templates/international/mexicoPromo2';
import usaPromo2Template from './templates/international/usaPromo2';


const addScript = (src) => {
  var s = document.createElement('script');
  s.setAttribute('src', src);
  document.body.appendChild(s);
  return new Promise((resolve) => {
    s.onload = () => resolve();
  });
};

const addStyle = (href) => {
  var s = document.createElement('link');
  s.setAttribute('rel', 'stylesheet');
  s.setAttribute('href', href);
  document.head.appendChild(s);
};

(function () {
  const pages = {
    national: {
      default: nationalTemplate,
      nationalPromo: nationalPromoTemplate
    },
    international: {
      default: internationalTemplate,
      /* expat: expatTemplate, */
      mexico: mexicoTemplate,
      usa: usaTemplate,
      latamUSA: latamUSATemplate,
      latam: latamTemplate,
      operadores: operadoresTemplate,
      expatPromo: expatPromoTemplate,
      latamPromo: latamPromoTemplate,
      mexicoPromo: mexicoPromoTemplate,
      usaPromo: usaPromoTemplate,
      expatPromo2: expatPromo2Template,
      latamPromo2: latamPromo2Template,
      mexicoPromo2: mexicoPromo2Template,
      usaPromo2: usaPromo2Template,
      /* develop: developTemplate */
    },
    novelas: {
      default: novelasTemplate,
    },
  };
  const mainPack = window.package || 'national';
  const initalScripts = [
    `https://assets.adobedtm.com/f3257b54648f/edaa808add31/launch-7455df5d204a.min.js`,
    `https://code.jquery.com/jquery-3.3.1.min.js`,
  ];

  const scripts = [
    `https://suscripcion.atresplayer.com/hazte-premium/nac/primer/js/vendor/modernizr-3.6.0.min.js`,
    `https://suscripcion.atresplayer.com/hazte-premium/nac/primer/js/plugins.js`,
    `https://suscripcion.atresplayer.com/hazte-premium/nac/primer/js/vendor/jquery.paroller.min.js`,
  ];

  const ownCode = {
    national: [
      `https://suscripcion.atresplayer.com/hazte-premium/nac/primer/js/main.js`,
    ],
    international: [
      `https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js`,
      `https://suscripcion.atresplayer.com/hazte-premium/internacional/main.js`,
    ],
    novelas: [
      `https://novelas-nova.atresplayer.com/hazte-premium/primer/main.js`,
    ],
  };

  const genericStyle =
    'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700';

  const ownStyle = {
    national: [
      'https://suscripcion.atresplayer.com/hazte-premium/nac/primer/css/normalize.css',
      `https://suscripcion.atresplayer.com/hazte-premium/nac/primer/css/main.css`
    ],
    international: [
      `https://suscripcion.atresplayer.com/hazte-premium/internacional/main.css`,
    ],
    novelas: [
      'https://suscripcion.atresplayer.com/hazte-premium/nac/primer/normalize.css',
      `https://novelas-nova.atresplayer.com/hazte-premium/primer/main.css`,
    ],
  };

  const templateStyle = {
    nationalPromo: [
      'https://suscripcion.atresplayer.com/hazte-premium/nac/primer/oferta/main.css'
    ],
    mexico: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/mexico/main.css',
    ],
    latam: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/main.css',
    ],
    latamUSA: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/main.css',
    ],
    usa: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/main.css',
    ],
     expat: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/expat/main.css',
    ],
    operadores: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/operadores/css/estiloa3p.css',
    ],
    mexicoPromo: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/mexico/promocion/main.css',
    ],
    latamPromo: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/promocion/main.css',
    ],
    usaPromo: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/promocion/main.css',
    ],
    expatPromo: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/expat/promocion/main.css',
    ],
    mexicoPromo2: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/mexico/promocion2/main.css',
    ],
    latamPromo2: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/latam/promocion2/main.css',
    ],
    usaPromo2: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/usa/promocion2/main.css',
    ],
    expatPromo2: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/expat/promocion2/main.css',
    ]
   /*  develop: [
      'https://suscripcion.atresplayer.com/hazte-premium/internacional/develop/css/estiloa3p.css',
    ]  */
  };

  // añade la fuente
  addStyle(genericStyle);

  // Este añade el del paquete o el de la platilla
  const templateCss = templateStyle[window.template || '-'] || [];
  if (templateCss.length) {
    templateCss.map(addStyle);
  } else {
    // añade el del paquete
    ownStyle[mainPack].forEach(addStyle);
  }

  // Añade los scripts iniciales
  Promise.all(initalScripts.map(addScript))
    .then(() => {
      // Añade los scripts dependientes (ej: los que dependen de Jquery)

      return Promise.all(scripts.map(addScript));
    })
    .then(() => {
      // Añaden los scripts para el paquete en concreto

      return Promise.all(ownCode[mainPack].map(addScript));
    })
    .then(() => {
      // Añade los que cambian los sliders

      return Promise.all((window.__sliderSrc || []).map(addScript));
    })

    .then(() => {
      if (navigator.userAgent.indexOf('atresplayer-native-ios') !== -1) {
        document.body.className += 'ios-native';
      }

      // Añade la plantilla correspondiente al paquete
      const mainContainer = document.getElementById('mainContainer');
      mainContainer.innerHTML =
        pages[mainPack][window.template || 'default'] ||
        pages[mainPack]['default'];

      // Añade el linkgenerator
      addScript(
        'https://statics.atresmedia.com/atresplayer/assets/scripts/landing/links-generator.js'
      );
    });
})();
