export default `<header class="hero flex bg-cover" id="top">
    <div class="wrapper cf">
      <hgroup class="hero__headings flex">
        <h1 class="title device">Suscripción PLAN atresplayer Premium Disney+ </h1>
        <strong class="claim">Aprovecha esta oportunidad única</strong>
        <div class="hero__planes">
          <p class="explanation">Las dos plataformas al mejor precio al contratar el PLAN premium de atresplayer y el Plan Estándar con anuncios de Disney+	</p>
          <div class="row">
            <!-- plan -->
            <div class="hero__plan">
              <div class="hero__plan-list">
                <h2 class="hero__plan-logo">PLAN <span>premium</span>
					<span class="feature-disney">
						<img src="https://suscripcion.atresplayer.com/plan-premium-disneyplus/img/logo-Disney+.png">
					</span>
				</h2>
				<span class="condition_plan">Plan Premium de atresplayer</span>
                <ul class="hero__list">
                  <li>Originales y exclusivos</li>
                  <li>Preestrenos de Atresmedia</li>
                  <li>Todo el catálogo de Atresmedia</li>
                </ul>
				<span class="condition_plan">Plan Estándar con anuncios de Disney+*</span>
				<ul class="hero__list">
                  <li>Todo el catálogo de Disney+</li>
                  <li>Lanzamientos internacionales</li>
                  <li>Originales producidos en España</li>
                </ul>
				
				<span class="condition_plan_disney">*Es necesario activar la cuenta de Disney+ en su plataforma.</span>
              </div>
              <div class="hero__plan-precios">
                <a href="" title="Plan premium Disney+" id="7541217" class="precio">
                  <span>8,49€<span class="alt">/mes</span></span>
                </a>
              </div>
            </div>

          </div>

        </div>
        
      </hgroup>
	  
	  <span class="hero__scroll bg-cover"></span>
    </div>
  </header>
  <section class="content">
    <div class="wrapper cf">
	  <h2 class="claim_features">Ventajas de contratar el <br />PLAN <span>atresplayer Premium Disney+</span></h2>
      <article id="originales" class="content__item flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/plan-premium-disneyplus/img/originales.png" alt="Originales y exclusivos">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/originales.svg">
          </span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Contenido original y exclusivo</h3>
          <p class="text">Accede a los mejores contenidos originales y exclusivos solo disponibles en atresplayer premium.</p>
          <a class="button" href="" title="Plan premium Disney+" id="7541217" >CONTRATAR</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-1.jpg" class="content-bg">
      </article>
      <article id="preestrenos" class="content__item flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/plan-premium-disneyplus/img/preestrenos.png" alt="Preestrenos">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/preestrenos.svg">
          </span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Preestrenos</h3>
          <p class="text">Adelántate a la emisión en televisión de tus series, programas y telenovelas favoritos con los preestrenos exclusivos premium.</p>
          <a class="button" href="" title="Plan premium Disney+" id="7541217">CONTRATAR</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-2.jpg" class="content-bg">
      </article>
      <article id="catalogo" class="content__item flex">
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/catalogo.png" alt="Todo el catálogo">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/features/clean/catalogo.svg">
          </span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.1" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Todo el catálogo de Atresmedia</h3>
          <p class="text">Disfruta de los últimos contenidos publicados y las emisiones en directo de nuestros canales.</p>
          <a class="button" href=""title="Plan premium Disney+" id="7541217">CONTRATAR</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-3.jpg" class="content-bg">
      </article>

      <article id="Disney+" class="content__item disney flex"> 
        <figure class="content__item-image">
          <img src="https://suscripcion.atresplayer.com/plan-premium-disneyplus/img/disney+.png" alt="Disney+">
          <span class="feature-arrow">
            <img src="https://suscripcion.atresplayer.com/plan-premium-disneyplus/img/features/clean/Disney+.svg">
          </span>
        </figure>
        <hgroup class="content__item__info flex parallax" data-paroller-factor="0.03" data-paroller-type="foreground" data-paroller-direction="vertical">
          <h3 class="title primary-color">Acceso a Disney+</h3>
          <p class="text">Contratando nuestro paquete combinado también tendrás la oportunidad de tener acceso a la plataforma de Disney+ y disfrutar de sus contenidos.</p>
          <a class="button" href="" title="Plan premium Disney+" id="7541217">CONTRATAR</a>
        </hgroup>
        <img src="https://suscripcion.atresplayer.com/hazte-premium/nac/primer/img/section-bg-4.jpg" class="content-bg"> 
      </article>
    </div>
  </section>

  <footer class="foo flex">
    <div class="wrapper cf">
      <p class="creds">© Atresmedia Corporación de Medios de Comunicación, S.A - A. Isla Graciosa 13, 28703, S.S. de los Reyes, Madrid. Reservados todos los derechos</p>
    </div>
  </footer>`;
